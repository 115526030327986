
//custom uikit-compatible theme
//color palette
$palette-light: #F3F2EE;
$palette-light-accent: #C68E4A;
$palette-main-accent: #D33F30;
$palette-dark-accent: #959899;
$palette-dark: #2D2A2E;
//uikit/material specific
$palette-success: #6bad74;
$palette-warning: #D5973E;
$palette-danger: #E6375A;

//uikit overrides
//color
$global-color: $palette-dark;
$global-border: lighten($palette-light-accent, 27.5%);
$global-muted-color: mix($palette-light-accent, $palette-dark, 50%);
$global-emphasis-color: $palette-dark;
$global-inverse-color: $palette-light;
$global-link-color: $palette-light-accent;
$global-link-hover-color: lighten($palette-light-accent, 10%);
//background
$global-background: lighten($palette-light, 5%);
$global-muted-background: $palette-light;
$global-secondary-background: $palette-dark;
$global-primary-background: $palette-main-accent;
$global-success-background: $palette-success;
$global-warning-background: $palette-warning;
$global-danger-background: $palette-danger;
$base-selection-background: $palette-light-accent;
$base-ins-background: #ffd;
$base-mark-background: #ffd;
//font
$global-font-size: 18px;